import {Link} from 'gatsby'
import React from 'react'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'

import styles from './project-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function ProjectPreview (props) {
  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      <article className={styles.portArtical}>
        <h3 className={styles.title}>{props.title}</h3>
        <div className={styles.leadMediaThumb}>
          {props.mainImage && props.mainImage.asset && (
            <img
              className={styles.imgPort}
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width(600)
                .height(Math.floor((9 / 16) * 600))
                .url()}
              alt={props.mainImage.alt}
            />
          )}
        </div>
        {props._rawExcerpt && (
          <div className={styles.excerpt}>
            {/* <BlockText blocks={props._rawExcerpt} /> */}
          </div>
        )}
      </article>
      <h3 className={styles.mobileTitle}>{props.title}</h3>

    </Link>
  )
}

// {props.title}
export default ProjectPreview

// Old Code Backup ===========

// function ProjectPreview (props) {
//   return (
//     <Link className={styles.root} to={`/project/${props.slug.current}`}>
//       <div className={styles.leadMediaThumb}>
//         {props.mainImage && props.mainImage.asset && (
//           <img
//             src={imageUrlFor(buildImageObj(props.mainImage))
//               .width(600)
//               .height(Math.floor((9 / 16) * 600))
//               .url()}
//             alt={props.mainImage.alt}
//           />
//         )}
//       </div>
//       <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
//       {props._rawExcerpt && (
//         <div className={styles.excerpt}>
//           <BlockText blocks={props._rawExcerpt} />
//         </div>
//       )}
//     </Link>
//   )
// }
